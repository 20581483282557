'use client';
import { clsx } from 'clsx';
import { usePathname } from 'next/navigation';
import type { MenuItemProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { MenuLink } from '../Menu';
import styles from './Pills.module.scss';

export const Pills = ({
  items,
  className = '',
  disableActiveState = false,
}: {
  items: Array<MenuItemProps>;
  className?: string;
  disableActiveState?: boolean;
}) => {
  const pathname = usePathname();

  return (
    <>
      {items.map((item) => {
        const destination = item.url ? new URL(item.url) : { pathname: '' };

        return (
          <MenuLink
            key={item.title}
            menuItem={item}
            className={clsx(
              styles.pill,
              { [styles.active]: !disableActiveState && destination.pathname === pathname },
              className
            )}
          />
        );
      })}
    </>
  );
};
