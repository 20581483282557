'use client';
import { usePathname } from 'next/navigation';
import Link from 'components/UI/Link/Link';
import { Title } from 'components/UI/Title';
import Typography from 'components/UI/Typography/Typography';
import { getLinkPathname } from 'utils/url';
import styles from './widget-tagpage-index-default.module.scss';

export type WidgetTagpageIndex = {
  readonly model?: {
    readonly letters?: Array<{
      readonly label: string;
      readonly url: string;
      readonly selected: boolean;
      readonly count: number;
    }>;
    readonly tagpages?: Array<{
      readonly label: string;
      readonly url: string;
    }>;
    readonly selected?: string;
  };
};

const WidgetTagpageIndexDefault = ({ context }: { context: WidgetTagpageIndex }) => {
  const currentPath = usePathname();

  const tagType = currentPath?.includes('autoren')
    ? 'Autor:innen'
    : currentPath?.includes('personen')
      ? 'Personen'
      : 'Themen';

  return (
    <section>
      <div className={styles.nav}>
        {context.model?.letters?.map((item) => {
          return (
            <span key={item.label} className={item.count <= 0 ? styles.disabled : ''}>
              {item.count <= 0 ? item.label : <a href={getLinkPathname(item.url)}>{item.label}</a>}
            </span>
          );
        })}
      </div>

      {!!context.model?.selected && (
        <Title>
          {tagType} mit {!!context.model?.selected && context.model.selected}
        </Title>
      )}

      {context.model?.tagpages?.length && (
        <div className={`${styles.grid} ${styles.persona}`}>
          {context.model?.tagpages?.map((item) => {
            return (
              <Link key={item.label} href={getLinkPathname(item.url)} className={styles.links}>
                <Typography as="h3">{item.label}</Typography>
              </Link>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default WidgetTagpageIndexDefault;
