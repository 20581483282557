'use client';
import { useContext, useState, useRef } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import type { Slide } from 'yet-another-react-lightbox';
import { getGalleryRawData } from 'api/getGalleryRawData';
import type { ApiImageProps } from 'components/UI/Image';
import { Teaser } from 'components/UI/Teaser';
import { AppContext } from 'context/AppContext';
import type { GtmContentFields } from 'types/analytics';
import type { AreaItem, BasisPageData, GalleryArticleModel } from 'types/cms';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { getAgofCode, getGTMContentFields, getHomeSectionPath, SectionMapper, trackPageView } from 'utils/analytics';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { Icon } from '../Icon';
import { TeaserNew } from '../TeaserNew';
import { GalleryButton } from './GalleryButton';
import { GallerySlide } from './GallerySlide';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';

declare module 'yet-another-react-lightbox' {
  interface SlideImage {
    image: ApiImageProps;
    title: string;
    loading?: boolean;
    count: number;
    fields: {
      [key: string]: string | object | Array<object>;
    };
  }
}

export interface GalleryImage {
  model: {
    image: ApiImageProps;
    title: string;
    fields: {
      [key: string]: string | object | Array<object>;
    };
  };
}

interface Props {
  teaser: WidgetTeaserItemProps;
  teaserImageWidth?: number;
  leadTeaserImageWidth?: number;
  index?: number;
}

type TrackingData = {
  agof?: string;
  ivw?: string;
  keywords?: string;
  sections?: GtmContentFields;
  adZone?: string;
};

export const Gallery = ({ teaser, teaserImageWidth, leadTeaserImageWidth, index }: Props) => {
  const {
    featureFlags,
    pageType,
    pageTitle,
    analyticsData: { ivwCode, homeSectionPath, articleFields },
    pageMetaData: { adZone, keywords },
  } = useContext(AppContext);

  const gtmFields = getGTMContentFields(pageTitle, pageType, homeSectionPath, articleFields);

  const [open, setOpen] = useState(false);
  const [galleryItems, setGalleryItems] = useState<Array<GalleryImage>>([]);
  const galleryAnalytics = useRef<TrackingData>({});
  const galleryUrl = new URL(teaser.model.url);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);
  const itemHasExternalLink = teaser.model.fields.externalUrl !== undefined && teaser.model.fields.externalUrl !== '';
  const isLeadImage = leadTeaserImageWidth && index === 0;

  const openGallery = () => {
    setOpen(true);
    if (galleryItems.length === 0) {
      getGalleryRawData(galleryUrl.pathname).then((res: BasisPageData) => {
        const galleryItems = res.areas.main
          ? res.areas.main.find((item: GalleryArticleModel) => item.view === 'gallery').items
          : [];

        const analyticsData = res.areas?.meta?.filter((areaItem: AreaItem) => areaItem.template == 'analytics')[0]
          .items[0];
        const marketingData = res.areas?.meta?.filter((areaItem: AreaItem) => areaItem.template == 'marketing')[0]
          .items[0];
        const homeSectionPath = getHomeSectionPath(analyticsData) || '';
        const sections = SectionMapper(homeSectionPath);

        galleryAnalytics.current = {
          agof: getAgofCode(sections),
          ivw: analyticsData.model.tracking.ivw,
          keywords: analyticsData.model.fields.keywords.split(',').map((k: string) => k.trim()),
          sections: sections,
          adZone: marketingData.model.marketing['ad.zone'],
        };

        trackGallery(1, galleryItems.length);
        setGalleryItems(galleryItems);
      });
    } else {
      trackGallery(1, galleryItems.length);
    }
  };

  const trackGallery = (currentIndex: number, length: number) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      content: undefined,
    });

    window.dataLayer.push({
      event: 'gallery_page_view',
      content: {
        type: 'gallery',
        url: teaser.model.url,
        ad_keywords: galleryAnalytics.current.keywords,
        ad_zone: galleryAnalytics.current.adZone,
        agof_code: galleryAnalytics.current.agof,
        ivw_code: galleryAnalytics.current.ivw,
        main_section: galleryAnalytics.current.sections?.main_section,
        ...galleryAnalytics.current.sections?.sub_sections,
        id: teaser.model.id,
        first_headline: teaser.model.fields?.['seo-title'] ?? '',
        headline: teaser.model.fields.title,
        tags: teaser.model['tag_items']?.map((tag) => tag.name) ?? [],
        first_publication_datetime: teaser.model.publishedAt,
        last_update_datetime: teaser.model.lastmodifiedAt,
        editorial_unit: teaser.model.fields?.ressort ?? '',
        number_of_pictures: length,
        current_picture: currentIndex,
      },
    });
  };

  const closeGallery = () => {
    trackPageView(gtmFields, ivwCode, adZone, keywords);
  };

  // REMOVE GITLAB ENV VARIABLE

  return (
    <>
      {isRefresh || isTeaserRefresh ? (
        <TeaserNew
          title={teaser.model.fields.title}
          subtitle={teaser.model.themenwelt?.section_name}
          onClick={openGallery}
          image={{
            image: teaser.model.image,
            width: isLeadImage ? leadTeaserImageWidth : teaserImageWidth,
            alt: teaser.model.fields.title,
          }}
          linkHref={itemHasExternalLink ? teaser.model.fields.externalUrl : teaser.model.url}
          variant={isLeadImage ? 'large' : 'medium'}
          subtitleColor={teaser.model.themenwelt?.primary_color}
          isSponsored={teaser.type === 'sponsored-article'}
          isGallery
          galleryCount={teaser.model.galleryCount ?? undefined}
        />
      ) : (
        <Teaser
          headline={teaser.model.fields.title}
          kicker={teaser.model.fields.subtitle}
          onClick={openGallery}
          image={{
            image: teaser.model.image,
            width: 375,
            alt: teaser.model.fields.title,
          }}
          isGallery
          isSmallOnMobile
        />
      )}

      <Lightbox
        open={open}
        close={() => {
          closeGallery();
          setOpen(false);
        }}
        slides={
          galleryItems.length > 0
            ? galleryItems.map((image, i) => ({
                src: image.model.image.defaultUrl,
                title: image.model.title,
                image: image.model.image,
                fields: image.model.fields,
                count: i + 1,
              }))
            : ([
                {
                  loading: true,
                },
              ] as Array<Slide>)
        }
        styles={{
          slide: { padding: 0 },
          button: { filter: 'none' },
          toolbar: { margin: '24px' },
        }}
        render={{
          slide: (props) => {
            const { slide } = props;
            return <GallerySlide slide={slide} />;
          },
          iconClose: () => {
            return <Icon name="cross" size={24} />;
          },
          buttonPrev: () => {
            return <GalleryButton variant="prev" trackGallery={trackGallery} />;
          },
          buttonNext: () => {
            return <GalleryButton variant="next" trackGallery={trackGallery} />;
          },
        }}
        carousel={{
          finite: true,
        }}
      />
    </>
  );
};
