'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import Image from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import { Logo } from 'components/UI/Logo';
import { Title, type TitleLink } from 'components/UI/TitleNew';
import { AppContext } from 'context/AppContext';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { linkUrl } from 'utils/url';
import styles from './widget-teaser-highlight.module.scss';

type ImageFormat = '1:1' | '3:4';

export type WidgetTeaserHighlightProps = {
  readonly model: {
    readonly fields: {
      readonly title?: string;
      readonly headline?: string;
      readonly link?: TitleLink;
      readonly imageFormat?: ImageFormat;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserHighlight = ({ context }: { context: WidgetTeaserHighlightProps }) => {
  const imageFormat = context.model.fields.imageFormat ?? '1:1';
  const itemCount = imageFormat === '3:4' ? 6 : 4;
  const width = 138;
  const { featureFlags } = useContext(AppContext);
  const enabled = isFeatureFlagEnabled('ft_new_highlight_teaser', featureFlags);

  const isRebrush = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  if (!enabled) {
    return null;
  }

  return (
    <section>
      <Title>{context.model.fields.title}</Title>
      <div className={styles.root}>
        <ul
          className={clsx(styles.grid, {
            [styles.image1_1]: imageFormat === '1:1',
            [styles.image3_4]: imageFormat === '3:4',
          })}
        >
          {context.items?.slice(0, itemCount).map((item) => (
            <li key={item.model.id} className={styles.item}>
              <Link className={styles.teaser} href={linkUrl(item.model.fields.externalUrl ?? item.model.url)}>
                <Image
                  image={item.model.image}
                  className={styles.image}
                  alt={`Bild zu: "${item.model.fields.title}"`}
                  title={item.model.fields.title}
                  width={width}
                  cropOverride={imageFormat === '3:4' ? 'c3_4' : 'c1_1'}
                />
                <div className={styles.text}>
                  {isRebrush &&
                    (item.model.themenwelt ? (
                      <Logo
                        size="tiny"
                        colors={item.model.themenwelt.logo_colors ?? ['#2a08b5', '#6f0bed', '#ec33ec']}
                      />
                    ) : (
                      <Logo size="tiny" variant="rtlDe" />
                    ))}
                  <div>{item.model.fields.title}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default WidgetTeaserHighlight;
