import type { PropsWithClassName } from 'types/react-props';
import { linkUrl } from 'utils/url';
import { Icon } from '../Icon';
import Image, { type ImageProps } from '../Image/Image';
import type { LinkProps } from '../Link/Link';
import Link from '../Link/Link';
import Typography from '../Typography/Typography';
import styles from './TeaserVideo.module.scss';

export type TeaserVideoProps = PropsWithClassName<{
  image: ImageProps;
  kicker?: string;
  headline?: string;
  link: LinkProps['href'];
  openLinkInNewTab?: boolean;
}>;

const TeaserVideo = ({ image, kicker, headline, link, openLinkInNewTab }: TeaserVideoProps) => {
  if (!image || !link) {
    return null;
  }

  return (
    <Link href={linkUrl(link)} target={openLinkInNewTab ? '_blank' : undefined}>
      <div className={styles.imageWrapper}>
        <Image image={image?.image} alt={image?.alt} width={image?.width} src={image?.src} className={styles.image} />
        <Icon name="playOutline" className={styles.playButton} />
      </div>
      {kicker && (
        <Typography as="div" variant="4xs" marginBottom="02">
          {kicker}
        </Typography>
      )}
      {headline && (
        <Typography as="div" variant="m">
          {headline}
        </Typography>
      )}
    </Link>
  );
};

export default TeaserVideo;
