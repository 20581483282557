'use client';
import { useContext } from 'react';
import Partials from 'components/partials/Partials';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import type { PartialWidgetContext } from 'types/cms';
import { getKey } from 'utils/idGenerator';
import styles from './group-grid-combi-ad.module.scss';

export type GroupGridProps = {
  readonly children: Array<PartialWidgetContext>;
};

const GroupGridCombiAd = ({ context }: { context: GroupGridProps }) => {
  const {
    pageMetaData: { noAds },
  } = useContext(AppContext);

  if (noAds) {
    return null;
  }

  return (
    <div className={styles.combiAd}>
      <Typography as="span" variant="5xs">
        Anzeige:
      </Typography>
      <div className={styles.wrapper}>
        <Typography as="h3" variant="3xs" className={styles.title}>
          Empfehlungen unserer Partner
        </Typography>
        {context.children.map((widget: PartialWidgetContext) => (
          <Partials key={getKey(widget.id, widget)} context={widget} />
        ))}
      </div>
    </div>
  );
};

export default GroupGridCombiAd;
