import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef, type RefObject } from 'react';

let openModals = 0;

export const useScrollLock = (ref: RefObject<HTMLElement | null>, isLocked: boolean = true) => {
  const wasLockedRef = useRef(false);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    if (isLocked) {
      wasLockedRef.current = true;
      disableBodyScroll(ref.current);
      openModals++;
    }

    return () => {
      if (wasLockedRef.current) {
        wasLockedRef.current = false;
        openModals--;

        if (openModals === 0) {
          clearAllBodyScrollLocks();
        }
      }
    };
  }, [isLocked, ref]);
};
