'use client';
import { useEffect, useRef, useState } from 'react';
import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import Icon from 'components/UI/Icon/Icon';
import type { TitleLink } from 'components/UI/TitleNew/Title';
import VideoSwiper from 'components/UI/VideoSwiper/VideoSwiper';
import type { ThemenweltProps } from 'types/cms';
import type { VideoProps } from 'types/videoTypes';
import styles from './widget-teaser-playlist.module.scss';

export type WidgetTeaserVideoPlaylistProps = {
  readonly model: {
    readonly fields: {
      readonly headline?: string;
      readonly link?: TitleLink;
    };
    readonly themenwelt?: ThemenweltProps;
  };
  readonly items?: Array<{
    readonly model: VideoProps;
  }>;
};

const WidgetTeaserPlaylist = ({ context }: { context: WidgetTeaserVideoPlaylistProps }) => {
  const { headline, link } = context.model.fields;
  const [isClient, setIsClient] = useState<boolean>(false);
  const videoList = context?.items?.map((item) => item.model) as Array<VideoProps>;
  const startType = useRef(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(-1);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const [totalClips, setTotalClips] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [contentFinished, setContentFinished] = useState<boolean>(false);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<VideoProps | undefined>(undefined);

  useEffect(() => setIsClient(true), []);

  useEffect(() => {
    if (contentFinished) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }

    return setContentFinished(false);
  }, [contentFinished, currentVideoIndex]);

  useEffect(() => {
    setCurrentVideo(currentVideoIndex === -1 ? undefined : videoList[currentVideoIndex]);
  }, [currentVideoIndex, videoList]);

  const bgColor = context.model.themenwelt?.secondary_color ?? '#020203';

  return (
    videoList && (
      <div className={styles.root} style={{ backgroundColor: `${bgColor}` }}>
        {currentVideo && isClient && (
          <div className={styles.video}>
            <FoundationPlayer
              video={currentVideo}
              postRollDisabled
              isAdPlayingListener={setIsAdPlaying}
              totalClipsListener={setTotalClips}
              totalDurationListener={setTotalDuration}
              currentAdTimeListener={setCurrentAdTime}
              contentFinishedListener={setContentFinished}
              videoPlayingListener={setVideoPlaying}
              autoplay
              currentVideoIndex={currentVideoIndex}
            />
          </div>
        )}

        <VideoSwiper
          onClick={(index: number) => {
            startType.current = index;
            setCurrentVideoIndex(index);
          }}
          videoList={videoList}
          isAdPlaying={isAdPlaying}
          totalAdClips={totalClips}
          totalAdDuration={totalDuration}
          currentAdTime={currentAdTime}
          title={headline}
          titleLink={link}
          currentVideoIndex={currentVideoIndex}
          videoPlaying={videoPlaying}
        />

        <div className={styles.meta}>
          <Icon name="playlist" />
          Playlist <span>•</span>
          {videoList.length} Videos
        </div>
      </div>
    )
  );
};

export default WidgetTeaserPlaylist;
