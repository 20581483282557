import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { Icon } from 'components/UI/Icon';
import Link from 'components/UI/Link/Link';
import { Logo } from 'components/UI/Logo';
import type { ThemenweltProps } from 'types/cms';
import type { PropsWithClassName } from 'types/react-props';
import styles from './Title.module.scss';

export type TitleLink = {
  readonly noFollow?: boolean;
  readonly url?: string;
  readonly name?: string;
  readonly target: string;
  readonly type: 'section-link' | 'external-link';
  readonly themenwelt?: ThemenweltProps;
};

export type TitleProps = PropsWithClassName<{
  readonly children: ReactNode;
  readonly link?: TitleLink;
  readonly as?: 'h2' | 'h3' | 'div';
  readonly plainLink?: boolean;
  readonly isRtlPlus?: boolean;
}>;

const Title = ({ link, children, as: DynamicTag = 'h2', className, plainLink, isRtlPlus = false }: TitleProps) => {
  if (!link?.url) {
    return (
      <div className={clsx(styles.root, className)}>
        <DynamicTag className={styles.title} data-id="section-title">
          {children}
        </DynamicTag>
      </div>
    );
  }

  return (
    <Link
      href={link.url}
      target={link.target}
      className={clsx(styles.root, styles.link, className)}
      noFollow={link.noFollow}
      plainLink={plainLink}
    >
      {(link.themenwelt || isRtlPlus) && (
        <Logo
          variant={isRtlPlus ? 'rtlPlus' : 'default'}
          className={styles.logo}
          colors={link.themenwelt?.logo_colors}
          size="headline"
        />
      )}
      <DynamicTag className={styles.title} data-id="section-title">
        {children}
      </DynamicTag>
      <Icon name="chevronRightNew" size={24} />
    </Link>
  );
};

export default Title;
