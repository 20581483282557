import { clsx } from 'clsx';
import { AdSlotName, AdSlotType } from 'types/ads';
import { NO_AD_AD_ZONE } from './AdSlot';
import styles from './AdSlot.module.scss';

export const isMobileAd = (adName: string) =>
  adName === 'mobile' || adName === 'outbrain-mobile' || adName === 'outstream_mobile';

export const getAdType = (adName: string) => (isMobileAd(adName) ? AdSlotType.Mobile : AdSlotType.Desktop);

export const keepName = (name: string) =>
  name === AdSlotName.Outstream ||
  name === AdSlotName.Opinary ||
  name === AdSlotName.Outbrain ||
  name === AdSlotName.HeaderKombi;

export const getAdslotName = (viewport: string, name: string, showPrediction: boolean, preserveSpace: boolean) =>
  clsx(
    'adwrapper',
    `adwrapper--${viewport}`,
    `adwrapper--${name}`,
    showPrediction && styles.adaPrediction,
    preserveSpace ? styles.preserveSpaceStatic : null
  );

export const shouldPreserveSpace = (adZone: string, name: string, isForMobile: boolean, position: number) =>
  ((adZone !== NO_AD_AD_ZONE && name === AdSlotName.Superbanner) || isForMobile) && position === 1;
