'use client';
import { clsx } from 'clsx';
import { type ApiImageProps } from 'components/UI/Image/Image';
import { Pills } from 'components/UI/Pills/Pills';
import { useNoNav } from 'customHooks/useNoNav';
import type { WidgetMenuProps } from '../widget-menu-default';
import styles from './widget-menu-bannerNavigation.module.scss';

export interface WidgetMenuBannerNavigationProps extends WidgetMenuProps {
  readonly items?: ReadonlyArray<{
    readonly type: string;
    readonly model: {
      readonly image: ApiImageProps;
      readonly title: string;
    };
  }>;
}

const WidgetMenuBannerNavigation = ({ context }: { context: WidgetMenuBannerNavigationProps }) => {
  const noNav = useNoNav();
  if (noNav) return null;

  let mobileImage, desktopImage;

  if (context.items) {
    mobileImage = context?.items?.length >= 1 && context.items[0].type === 'picture' ? context.items[0] : undefined;
    desktopImage = context?.items?.length >= 2 && context.items[1].type === 'picture' ? context.items[1] : undefined;
  }

  return (
    <div className={styles.menu}>
      {(mobileImage || desktopImage) && (
        <div className={styles.imageWrapper}>
          {mobileImage && (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              className={clsx(styles.image, { [styles.mobile]: desktopImage })}
              src={`${mobileImage.model.image.baseUrl}mobileBanner.webp`}
              alt={mobileImage.model.title}
            />
          )}
          {desktopImage && (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              className={clsx(styles.image, styles.desktop)}
              src={`${desktopImage.model.image.baseUrl}mobileBanner.webp`}
              alt={desktopImage.model.title}
            />
          )}
        </div>
      )}
      <div className={styles.navigation}>
        <Pills className="banner-navi-pill" items={context.model.menu} />
      </div>
    </div>
  );
};

export default WidgetMenuBannerNavigation;
