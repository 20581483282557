'use client';
import { useContext } from 'react';
import Partials from 'components/partials/Partials';
import { AppContext } from 'context/AppContext';
import type { PartialWidgetContext } from 'types/cms';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { getKey } from 'utils/idGenerator';

export type GroupGridProps = {
  readonly children: Array<PartialWidgetContext>;
};

const GroupGridTopic = ({ context }: { context: GroupGridProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./group-grid-topic-new.module.scss') : require('./group-grid-topic.module.scss');

  return (
    <div className={`${styles.wrapper} topics-body`}>
      {context.children.map((widget: PartialWidgetContext) => (
        <Partials key={getKey(widget.id, widget)} context={widget} />
      ))}
    </div>
  );
};

export default GroupGridTopic;
