'use client';
import { useContext, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { AnimatePresence, motion, useReducedMotion } from 'motion/react';
import { usePathname } from 'next/navigation';
import Partials from 'components/partials/Partials';
import type { MenuItemProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { AppContext } from 'context/AppContext';
import { useNoNav } from 'customHooks/useNoNav';
import type { GroupGridProps } from 'types/group';
import { getThemenweltFromSectionData } from 'utils/areaSearch';
import { generateWeakKey } from 'utils/idGenerator';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Logo } from '../Logo';
import { MenuLink } from '../Menu';
import styles from './Header.module.scss';

export const Header = ({ context }: { context: GroupGridProps }) => {
  const noNav = useNoNav();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const shouldReduceMotion = useReducedMotion();
  const { rawPageData } = useContext(AppContext);
  const sectionThemenwelt = getThemenweltFromSectionData(rawPageData?.meta);

  const menu = context.children.find((child) => {
    return child.view === 'themenwelten';
  });

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);

      if (currentScrollY > lastScrollY && currentScrollY > 64) {
        setIsHeaderVisible(false);
      } else if (currentScrollY < lastScrollY) {
        setIsHeaderVisible(true);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handler = (e: { code: string }) => {
      if (isOpen && e.code === 'Escape') {
        setIsOpen(false);
        document.body.style.overflow = '';
      }
    };

    window.addEventListener('keydown', handler, false);

    return () => {
      window.removeEventListener('keydown', handler, false);
    };
  }, [pathname, isOpen]);

  const toggleNaviLayer = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = '';
    } else {
      setIsOpen(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const getActiveMenuItem = () => {
    return menu?.model.menu.find((item: MenuItemProps) => {
      return item.active;
    });
  };

  const colors = sectionThemenwelt?.logo_colors ??
    getActiveMenuItem()?.themenwelt?.logo_colors ?? ['#2a08b5', '#6f0bed', '#ec33ec'];

  if (noNav) return null;

  return (
    <>
      <div className={styles.logo}>
        <Link href="/" title="Home">
          <Logo variant="default" size="header" colors={colors} />
        </Link>
      </div>
      <header
        className={clsx(styles.mainNavigation, {
          [styles.scrolled]: isHeaderVisible && scrollY > 200,
        })}
      >
        <ul className={styles.menu}>
          {menu?.model.menu.map((item: MenuItemProps) => (
            <li
              className={clsx(styles.menuItem, item.active ? styles.active : '')}
              key={item.title}
              style={{ borderBottom: `2px solid ${item?.themenwelt?.primary_color ?? '#000'}` }}
            >
              <MenuLink menuItem={item} dataVarsAction="level_1" className="top-level" />
            </li>
          ))}
        </ul>
      </header>
      <button className={clsx(styles.search, styles.button)} onClick={toggleNaviLayer} aria-label="Navigation & Suche">
        <Icon name="search" size={18} viewBox="0 0 18 18" />
      </button>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? 0 : '100%' }}
        exit={{ x: '100%' }}
        transition={{ duration: shouldReduceMotion ? 0 : 0.3, ease: 'easeInOut' }}
        className={clsx(styles.navLayer, { [styles.naviLayerClosed]: !isOpen })}
        data-testid={isOpen ? 'open' : 'closed'}
      >
        <button className={clsx(styles.button, styles.close)} onClick={toggleNaviLayer} aria-label="Schließen">
          <Icon name="close" size={16} />
        </button>
        {context.children.map((context) => (
          <Partials key={generateWeakKey(context, 'navi-layer')} context={context} />
        ))}
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isOpen ? 0.7 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: shouldReduceMotion ? 0 : 0.3, ease: 'easeInOut' }}
            className={styles.backdrop}
            onKeyDown={() => {}}
            onClick={toggleNaviLayer}
            role="button"
            tabIndex={-1}
          />
        )}
      </AnimatePresence>
    </>
  );
};
