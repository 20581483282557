'use client';
import { useEffect, useRef, useState, type PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from 'types/react-props';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import styles from './Collapsible.module.scss';

export type CollapsibleProps = PropsWithClassName<
  PropsWithChildren<{
    readonly showMoreText: string;
    readonly showLessText: string;
  }>
>;

const Collapsible = ({ showMoreText, showLessText, children, className }: CollapsibleProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isShown, setIsShown] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isShown]);

  return (
    <div className={className}>
      <div
        ref={contentRef}
        className={styles.contentWrapper}
        aria-hidden={!isShown}
        style={{ height: isShown ? contentHeight : 0 }}
      >
        <Typography className={styles.content} as="div">
          {children}
        </Typography>
      </div>
      <button className={styles.button} onClick={() => setIsShown(!isShown)}>
        <Icon name="chevronAltDown" className={clsx({ [styles.rotate]: isShown })} size={12} />
        <Typography className={styles.buttonText} variant="4xs" as="span">
          {isShown ? showLessText : showMoreText}
        </Typography>
      </button>
    </div>
  );
};

export default Collapsible;
