import { Teaser } from 'components/UI/Teaser';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import type { TitleLink } from 'components/UI/Title/Title';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { linkUrl } from 'utils/url';

export const isRtlPlusType = (type: string): boolean => type === 'rtl-plus-episodes' || type === 'rtl-plus-series';

export type WidgetTeaserButtonProps = {
  readonly model: {
    readonly fields: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly articleButtonText?: string;
      readonly rtlPlusButtonText?: string;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserButton = ({ context }: { context: WidgetTeaserButtonProps }) => {
  if (!context.items || context.items.length <= 0) {
    return null;
  }

  const width = 748;

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      gridColumns={2}
      anchor={context.model?.fields?.anchor}
    >
      {context.items.slice(0, 2).map((item) => {
        if (!isRtlPlusType(item.type)) {
          return (
            <Teaser
              key={item.model.id}
              headline={item.model.fields.title}
              kicker={item.model.fields.subtitle}
              image={{
                image: item.model.image,
                width: width,
                alt: `Bild zu: "${item.model.fields.title}"`,
              }}
              buttonText={context.model.fields.articleButtonText ?? 'Hier reinklicken'}
              linkHref={linkUrl(item.model.url)}
              isSponsored={item.type === 'sponsored-article'}
            />
          );
        } else {
          return (
            <Teaser
              key={item.model.id}
              headline={item.model.fields.title}
              image={{
                image: item.model.image,
                width: width,
                alt: `Bild zu: "${item.model.fields.title}"`,
              }}
              linkHref={item.model.fields.url ? linkUrl(item.model.fields.url) : linkUrl(item.model.url)}
              buttonText={context.model.fields.rtlPlusButtonText ?? 'Jetzt streamen auf RTL+'}
              buttonVariant="filled"
              openLinkInNewTab
            />
          );
        }
      })}
    </TeaserGridSection>
  );
};

export default WidgetTeaserButton;
