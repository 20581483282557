'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import { Typography } from '../Typography';
import styles from './Pagination.module.scss';

export type PaginationProps = {
  baseUrl?: string;
  currentPage: number;
  lastPage: number;
  suffix?: string;
};

type PaginationItemProps = {
  href: string;
  number: number;
  active: boolean;
};

const PaginationItem = ({ href, number, active }: PaginationItemProps) => {
  if (active) {
    return (
      <Typography as="span" variant="m" className={styles.active}>
        {number}
      </Typography>
    );
  }
  return (
    <Link href={href}>
      <Typography as="span" variant="m">
        {number}
      </Typography>
    </Link>
  );
};

const SubPagination = ({ baseUrl, currentPage, lastPage, suffix }: PaginationProps) => {
  const firstSubPaginationPageNumber = currentPage * 10 + 1;
  const subPaginationNumbers = Array.from({ length: 10 }, (_, index) => firstSubPaginationPageNumber + index);
  const suffixString = suffix ? `.${suffix}` : '';

  return (
    <nav aria-label="Sub Pagination">
      <ul className={styles.subPagination}>
        {subPaginationNumbers.map(
          (number) =>
            number <= lastPage && (
              <li key={`${baseUrl}-${number}`} className={styles.subItem}>
                <Link href={`${baseUrl}/seite-${number}${suffixString}`}>
                  <Typography as="span" variant="4xs">
                    {number}
                  </Typography>
                </Link>
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

const Pagination = ({ currentPage, lastPage, suffix }: PaginationProps) => {
  const startingNumber = lastPage - currentPage <= 5 ? lastPage - 9 : currentPage - 4;
  const firstStartingNumber = currentPage <= 5 || lastPage <= 10 ? 1 : startingNumber;
  const paginationNumbers = Array.from(
    { length: lastPage < 10 ? lastPage : 10 },
    (_, index) => firstStartingNumber + index
  );
  const appContext = useContext(AppContext);
  const requestPath = appContext?.requestPath;

  //check if the last path section of requestPath contains seite-<number>.html
  const lastPathSection = requestPath?.split('/').pop() || '';
  const isPage = lastPathSection.startsWith('seite-') && lastPathSection.endsWith('.html');

  //if it is a page, we remove the last section
  const baseUrlString = isPage
    ? requestPath?.slice(0, requestPath.length - lastPathSection.length - 1)
    : currentPage == 1
      ? requestPath?.slice(0, requestPath.length - 5)
      : requestPath;

  const suffixString = suffix ? `.${suffix}` : '';
  const backUrl =
    currentPage != 2 ? `${baseUrlString}/seite-${currentPage - 1}${suffixString}` : baseUrlString + suffixString;

  return (
    <div>
      <nav aria-label="Pagination" className={styles.root}>
        {currentPage !== 1 && (
          <div className={clsx(styles.gridItem, styles.prevWrapper)}>
            <Link href={baseUrlString + suffixString} aria-label="Erstes Element" className={styles.firstItemLink}>
              <Icon name="chevronLeft" size={24} />
              <Icon name="chevronLeft" size={24} />
            </Link>
            <Link href={backUrl} aria-label="Zurück">
              <Icon name="chevronLeft" size={24} />
            </Link>
          </div>
        )}
        {currentPage !== lastPage && (
          <div className={clsx(styles.gridItem, styles.nextWrapper)}>
            <Link href={`${baseUrlString}/seite-${currentPage + 1}${suffixString}`} aria-label="Weiter">
              <Icon name="chevronRight" size={24} />
            </Link>
            <Link
              href={`${baseUrlString}/seite-${lastPage}${suffixString}`}
              aria-label="Letztes Element"
              className={styles.lastItemLink}
            >
              <Icon name="chevronRight" size={24} />
              <Icon name="chevronRight" size={24} />
            </Link>
          </div>
        )}
        <ul className={clsx(styles.gridItem, styles.itemWrapper)}>
          {paginationNumbers.map((number) => {
            const href = `${baseUrlString}${number === 1 ? suffixString : '/seite-' + number + suffixString}`;

            return (
              <li key={href} className={clsx(styles.item, number === currentPage && styles.active)}>
                <PaginationItem href={href} number={number} active={number === currentPage} />
              </li>
            );
          })}
        </ul>
      </nav>
      {currentPage <= 9 && currentPage * 10 < lastPage && (
        <SubPagination baseUrl={baseUrlString} currentPage={currentPage} lastPage={lastPage} suffix={suffix} />
      )}
    </div>
  );
};

export default Pagination;
