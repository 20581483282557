import { FALLBACK_IMAGE_URL } from 'components/UI/FallbackImage/FallbackImage';
import type { AllowedCropTypes, ApiImageProps } from 'components/UI/Image';
import { getImageLoader } from 'utils/imageLoader';
import type { ImageObject, PublisherObject } from '../SchemaObjects.interface';

export const getSeoTitle = (primary?: string, secondary?: string): string => {
  if (!primary && !secondary) {
    return '';
  }

  if (!secondary && primary) {
    return primary;
  }

  return secondary + (primary ? ': ' + primary : '');
};

export const rtlPublisherObject: PublisherObject = {
  '@type': 'Organization',
  name: 'RTL.de',
  url: 'https://www.rtl.de',
  logo: {
    '@type': 'ImageObject',
    url: 'https://www.rtl.de/rtl_logo.png',
    width: 1200,
    height: 630,
  },
};

export const rtlFallbackImageObject: ImageObject = {
  '@type': 'ImageObject',
  url: FALLBACK_IMAGE_URL,
  width: 1200,
  height: 675,
};

export const getImageObject = (image: ApiImageProps, overwritecrop?: AllowedCropTypes): ImageObject => {
  if (!image) {
    return rtlFallbackImageObject;
  }

  return {
    '@type': 'ImageObject',
    url: getImageLoader(image.baseUrl, image.filename, overwritecrop ?? image.crop).loader({
      src: image.baseUrl,
      width: image.originalWidth,
    }),
    width: image.originalWidth,
    height: image.originalHeight,
  };
};
