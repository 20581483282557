import type { AreaItem, ArticleHeadFields, SectionDataItemFields } from '../types/cms';
import { getArticleHeadDataByAttribute, getSectionDataByAttributeValue } from './areaSearch';

const disableAds = (
  data?: Array<AreaItem>,
  articleField = 'is-ad-free' as keyof ArticleHeadFields,
  sectionField = 'no-ads' as keyof SectionDataItemFields
): boolean => {
  if (!data) {
    return false;
  }

  return (
    getArticleHeadDataByAttribute(articleField, data) === 'true' ||
    getSectionDataByAttributeValue(sectionField, data) === 'true'
  );
};

export const disableDisplayAds = (data?: Array<AreaItem>): boolean => {
  return disableAds(data);
};

export const disableVideoAds = (data?: Array<AreaItem>): boolean => {
  return disableAds(data, 'is-video-ad-free', 'no-video-ads');
};

export const getVpShare = (path: string) => {
  let pathArray = path
    .split('/')
    .filter((n) => n)
    .filter((n) => n !== 'rtl_portal');

  if (pathArray.length > 3) {
    pathArray = pathArray.slice(0, 3);
  }

  if (pathArray?.[0]?.toLowerCase().match(/^(show|sendungen)$/)) {
    if (pathArray?.[1]?.toLowerCase().match(/^(show|real-life|serie|comedy|spezial|life|news)$/)) {
      const showName = pathArray[2];
      pathArray = [showName];
    } else {
      pathArray = pathArray.slice(1, 2);
    }
  }

  return `rtl_rtl/${pathArray.join('_')}`;
};
