'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { usePathname } from 'next/navigation';
import { AppContext } from 'context/AppContext';
import { useMutationObserver } from 'customHooks/useMutationObserver';
import { useQueryParam } from 'customHooks/useQueryParam';
import { AdSlotName } from 'types/ads';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { keepName } from './AdSlotHelper';

const OUTBRAIN_TEMPLATE = 'DE_RTL.de';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OBR?: any;
  }
}

export const DefaultAd = ({
  adName,
  className,
  minHeight,
  fullAdSlotName,
  type,
  hidePlaceholder = false,
}: {
  adName: string;
  className: string;
  minHeight: string;
  fullAdSlotName: string;
  type: string;
  hidePlaceholder?: boolean;
}) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./AdslotNew.module.scss') : require('./AdSlot.module.scss');

  const mutationRef = useRef<HTMLDivElement>(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const removePlaceholder = () => {
    if (mutationRef?.current?.classList.contains('GujAdInUse')) {
      setShowPlaceholder(false);
    }
  };

  useMutationObserver(mutationRef, removePlaceholder, { attributes: true, attributeFilter: ['class'] });

  if (adName === AdSlotName.HeaderKombi) {
    return (
      <div
        className={clsx(className, 'ada-preservespace-superbanner_1-wallpaper_1')}
        style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}
      >
        {isRefresh && showPlaceholder && !hidePlaceholder && <div className={styles.adPlaceholder}>- Anzeige -</div>}
        <div
          id="wallpaper_1"
          data-device={type}
          className={keepName(adName) ? '' : 'gujAd'}
          data-ada-container={`wallpaper_1_${type}`}
          ref={mutationRef}
        ></div>
        <div
          id="superbanner_1"
          data-device={type}
          className={keepName(adName) ? '' : 'gujAd'}
          data-ada-container={`superbanner_1_${type}`}
          ref={mutationRef}
        ></div>
      </div>
    );
  }

  return (
    <div className={className} style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}>
      {isRefresh && showPlaceholder && !hidePlaceholder && <div className={styles.adPlaceholder}>- Anzeige -</div>}
      <div
        id={fullAdSlotName}
        data-device={type}
        className={keepName(adName) ? '' : 'gujAd'}
        data-ada-container={`${fullAdSlotName}_${type}`}
        ref={mutationRef}
      ></div>
    </div>
  );
};

export const OutbrainAd = ({
  widgetId = 'SF_5',
  className,
  fullAdSlotName,
}: {
  widgetId?: string;
  className?: string;
  fullAdSlotName?: string;
}) => {
  const pathName = usePathname();
  const device = useQueryParam('device');
  const [userAdId, setUserAdId] = useState('null');

  const fullPath = `https://www.rtl.de${pathName}`;
  const isSmartfeed = widgetId === 'AR_10' || widgetId === 'AR_20';

  useEffect(() => {
    const adId = document.cookie
      .split('; ')
      .find((row) => row.startsWith('AdId='))
      ?.split('=')[1];
    setUserAdId(adId ?? 'null');

    window?.OBR && window.OBR.extern.researchWidget();
  }, []);

  if (isSmartfeed && !!device && ['android', 'ios'].includes(device.toLowerCase())) {
    return (
      <div className={className} data-ada-container={fullAdSlotName} data-ob-identifier={`ad-outbrain-${widgetId}`}>
        <div
          className="OUTBRAIN"
          data-src={fullPath}
          data-widget-id={device === 'iOS' ? 'MB_1' : 'MB_11'}
          data-ob-user-id={userAdId}
          data-ob-installation-key={device === 'iOS' ? 'DERTL2DL5GFLPIBD78HOPEBA6' : 'DERTL2I6IBI3O1OCGN7EMP9C3'}
        />
      </div>
    );
  }

  return (
    <div className={className} data-ada-container={fullAdSlotName} data-ob-identifier={`ad-outbrain-${widgetId}`}>
      <div className="OUTBRAIN" data-src={fullPath} data-widget-id={widgetId} data-ob-template={OUTBRAIN_TEMPLATE} />
    </div>
  );
};
