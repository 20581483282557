'use client';
import { useEffect, useRef, useState } from 'react';
import { hasVendorConsent } from 'components/lib/Sourcepoint/ConsentContext';
import { openPrivacyCenter, TCF_PURPOSE_IDS, TCF_VENDOR_IDS } from 'components/lib/Sourcepoint/tcf';
import { Typography } from 'components/UI/Typography';
import { useConsent } from 'customHooks/useConsent';
import { useConsentReady } from 'customHooks/useConsentReady';
import type { StorylineEmbedType } from 'types/storylines';
import styles from './Embed.module.scss';

export type OembedResponse = {
  html?: string;
  message?: string;
};

const getVendorIdByEmbedValue = (uri: string): string => {
  if (uri.includes('instagram')) {
    return TCF_VENDOR_IDS.INSTAGRAM;
  }
  if (uri.includes('facebook')) {
    return TCF_VENDOR_IDS.FACEBOOK;
  }
  if (uri.includes('twitter')) {
    return TCF_VENDOR_IDS.TWITTER;
  }
  if (uri.includes('youtube')) {
    return TCF_VENDOR_IDS.YOUTUBE;
  }
  return '';
};

const isSocialEmbed = (uri: string): boolean => {
  return uri.includes('instagram') || uri.includes('facebook') || uri.includes('twitter') || uri.includes('youtube');
};

const EnableConsent = () => {
  return (
    <div className={styles.enableConsent}>
      <Typography variant="4xs">
        <strong>Empfohlener Inhalt</strong>
      </Typography>
      <Typography variant="4xs">
        Aufgrund Ihrer Privacy-Einstellungen können wir an dieser Stelle keinen Inhalt einer externen Plattform
        anzeigen. Sie können sich diesen Inhalt durch Anpassen ihrer Privacy-Einstellungen anzeigen lassen.
      </Typography>
      <button className={styles.consentCta} onClick={openPrivacyCenter} aria-haspopup="dialog" aria-expanded="false">
        <Typography variant="4xs" as="span">
          <strong>Hier Privacy-Einstellungen anpassen</strong>
        </Typography>
      </button>
    </div>
  );
};

export default function Embed({ model }: { readonly model: StorylineEmbedType }) {
  const { consent } = useConsent();
  const consentReady = useConsentReady();

  const [fetchedData, setFetchedData] = useState<OembedResponse>();
  const [consentDeclined, setConsentDeclined] = useState<boolean>(false);

  useEffect(() => {
    const consentedPurpose = hasVendorConsent(TCF_PURPOSE_IDS.FREMDINHALTE, consent.consentedPurposes);
    const consentedVendor = hasVendorConsent(getVendorIdByEmbedValue(model.fields.uri.value), consent.consentedVendors);

    if (consentReady && isSocialEmbed(model.fields.uri.value) && (!consentedPurpose || !consentedVendor)) {
      setConsentDeclined(true);
    }
  }, [consent, model, consentReady]);

  useEffect(() => {
    fetch(`https://embeds.rtl.de/oembed?url=${encodeURIComponent(model.fields.uri.value)}`)
      .then((res) => res.json())
      .then((data) => {
        setFetchedData(data);
      })
      .catch(() => setFetchedData({ message: 'error' }));
    return () => {
      setFetchedData(undefined);
    };
  }, [model]);

  if (fetchedData?.message) {
    return <></>;
  }

  if (consentDeclined) {
    return <EnableConsent />;
  }

  return <>{fetchedData?.html ? <EmbedHTML script={fetchedData.html} /> : <div className={styles.loading}></div>}</>;
}

const EmbedHTML = ({ script }: { script: string }) => {
  const scriptRoot = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const range = document.createRange();
    const documentFragment = range.createContextualFragment(script);
    scriptRoot.current?.append(documentFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={scriptRoot} data-testid="embed" className={styles.root} />;
};
