'use client';
import { useContext } from 'react';
import { AdInsideList } from 'components/UI/AdInsideList/AdInsideList';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import { TeaserGridSection as TeaserGridSectionNew } from 'components/UI/TeaserGridSectionNew';
import { AppContext } from 'context/AppContext';
import type { WidgetTeaserStandardProps } from 'types/cms-teaser';
import { isFeatureFlagEnabled } from 'utils/featureFlags';

const WidgetTeaserStandard = ({
  context,
  isSponsored,
  gridColumns,
}: {
  context: WidgetTeaserStandardProps;
  isSponsored?: boolean;
  gridColumns?: 2 | 3 | 5;
}) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  const width = 375;

  if (isRefresh || isTeaserRefresh) {
    const newWidth = 735;

    return (
      <TeaserGridSectionNew
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        isSponsored={isSponsored}
        anchor={context.model?.fields?.anchor}
      >
        <AdInsideList
          context={context}
          teaserImageWidth={newWidth}
          firstTeaserHighlight={context.model?.fields?.firstTeaserHighlight}
        />
      </TeaserGridSectionNew>
    );
  }

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      isSponsored={isSponsored}
      gridColumns={gridColumns}
      anchor={context.model?.fields?.anchor}
    >
      <AdInsideList context={context} teaserImageWidth={width} />
    </TeaserGridSection>
  );
};

export default WidgetTeaserStandard;
