import { useCallback, useEffect, type RefObject } from 'react';

/**
 * Triggers a callback when the passed key is pressed
 */
export const useKey = (
  isActive: boolean,
  key: string,
  callback?: (event: KeyboardEvent) => void,
  target?: RefObject<HTMLElement | null>
) => {
  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (key === event.key && callback) {
        callback(event);
      }
    },
    [key, callback]
  );

  useEffect(() => {
    if (typeof window === 'undefined' || !isActive) {
      return;
    }

    const targetNode = (target?.current ?? window) as HTMLElement;

    targetNode.addEventListener('keydown', onKeyPress);
    return () => {
      targetNode.removeEventListener('keydown', onKeyPress);
    };
  }, [isActive, onKeyPress, target]);
};
