import { type RefObject, useEffect } from 'react';

export const useMutationObserver = (
  ref: RefObject<Node | null>,
  callback: () => void,
  options: MutationObserverInit
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [ref, callback, options]);
};
