import { useEffect, type RefObject } from 'react';

const focusableTags = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const isElementHidden = (el: HTMLElement) =>
  (el.offsetWidth <= 0 && el.offsetHeight <= 0) || el.style.display === 'none';

const isElementVisible = (element: HTMLElement, boundaryElement: HTMLElement) => {
  let parentElement: HTMLElement = element;
  while (parentElement) {
    if (parentElement === boundaryElement) {
      break;
    }
    if (isElementHidden(parentElement)) {
      return false;
    }
    parentElement = parentElement.parentNode as HTMLElement;
  }

  return true;
};

const getFocusableElements = (rootNode: HTMLElement) =>
  (Array.from(rootNode.querySelectorAll(focusableTags)) as Array<HTMLElement>).filter(
    (el) => isElementVisible(el, rootNode) && !el.hasAttribute('disabled')
  );

export const useFocusTrap = (isActive: boolean, rootNodeRef: RefObject<HTMLElement | null>) => {
  useEffect(() => {
    if (!isActive || !rootNodeRef?.current) {
      return;
    }

    const rootNode = rootNodeRef?.current;
    const focusableElements = getFocusableElements(rootNode);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (event: KeyboardEvent) => {
      const isTabPressed = event.key === 'Tab';
      if (!isTabPressed) {
        return;
      }

      if (event.shiftKey && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }

      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [rootNodeRef, isActive]);
};
