'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import Iframe from 'components/UI/Embeds/Iframe';
import { Title } from 'components/UI/Title';
import type { TitleLink } from 'components/UI/Title/Title';
import { Title as TitleNew } from 'components/UI/TitleNew';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import styles from './widget-iframe-default.module.scss';

export type WidgetIframeProps = {
  readonly id: string;
  readonly model: {
    readonly fields: {
      readonly headline?: string;
      readonly link?: TitleLink;
      readonly iframe: string;
      readonly width?: string;
      readonly height?: string;
      readonly widthMobile?: string;
      readonly heightMobile?: string;
      readonly targetUrl?: string;
      readonly openInNewTab?: boolean;
      readonly contentstreams?: object;
    };
  };
};

const WidgetIframe = ({ context }: { context: WidgetIframeProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  const sectionRef = useRef<HTMLElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (sectionRef.current) {
      if (sectionRef.current.offsetWidth > 900) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const fields = context.model.fields;

  const iframeWidth = isMobile && fields.widthMobile ? fields.widthMobile : fields.width;
  const iframeHeight = isMobile && fields.heightMobile ? fields.heightMobile : fields.height;

  return (
    <section ref={sectionRef}>
      {fields.headline && !isRefresh && !isTeaserRefresh && <Title link={fields?.link}>{fields.headline}</Title>}
      {fields.headline && (isRefresh || isTeaserRefresh) && <TitleNew link={fields?.link}>{fields.headline}</TitleNew>}
      {fields.targetUrl ? (
        <a className={styles.link} href={fields.targetUrl} target={fields.openInNewTab ? '_blank' : undefined}>
          <Iframe
            className={styles.iFrameWithLink}
            src={fields.iframe}
            width={iframeWidth}
            height={iframeHeight}
            id={context.id}
          />
        </a>
      ) : (
        <Iframe src={fields.iframe} width={iframeWidth} height={iframeHeight} id={context.id} />
      )}
    </section>
  );
};

export default WidgetIframe;
