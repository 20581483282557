'use client';
import { useContext } from 'react';
import { Pills } from 'components/UI/Pills/Pills';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import type { WidgetMenuProps } from './widget-menu-default';

const WidgetMenuPill = ({ context }: { context: WidgetMenuProps }) => {
  const { featureFlags } = useContext(AppContext);
  const getPillNaviFromSiteManager = isFeatureFlagEnabled('ft_temp_pill_navi_sitemanager', featureFlags);

  if (!getPillNaviFromSiteManager) {
    return <></>;
  }

  return (
    <div>
      <Pills items={context.model.menu} disableActiveState />
    </div>
  );
};

export default WidgetMenuPill;
