'use client';
import { useContext, useEffect } from 'react';
import { clsx } from 'clsx';
import { AdAllianceLib } from 'components/lib/AdAlliance/adAllianceLib';
import { predictionMinHeight } from 'components/lib/AdSizePrediction';
import { AppContext } from 'context/AppContext';
import { useAdDebug } from 'customHooks/useAdDebug';
import { AdSlotName, AdSlotType } from 'types/ads';
import type { PropsWithClassName } from 'types/react-props';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { getAdslotName, keepName, shouldPreserveSpace } from './AdSlotHelper';
import { DefaultAd, OutbrainAd } from './componentsToRender';

export type AdSlotProps = PropsWithClassName<{
  readonly name: AdSlotName;
  readonly position?: number;
  readonly viewport?: AdSlotType;
  readonly outbrainWidgetId?: string;
  readonly horizontalAlign?: 'left' | 'center' | 'right';
  readonly skipPrediction?: boolean;
}>;

const preserveSpaceStatic = '90px';

export const NO_AD_AD_ZONE = '_noad';

const isOutbrain = (name: string) => name === AdSlotName.Outbrain || name === AdSlotName.OutbrainMobile;

const shouldPushAd = (
  name: string,
  fullAdSlotName: string,
  displayBasedOnViewport: boolean,
  pushedAdSlots: Array<string>
) =>
  !isOutbrain(name) &&
  displayBasedOnViewport &&
  !pushedAdSlots.includes(fullAdSlotName) &&
  name !== AdSlotName.Outstream;

export const AdSlot = ({
  name,
  position = 1,
  viewport = AdSlotType.Default,
  outbrainWidgetId,
  horizontalAlign = 'center',
  skipPrediction = false,
  className,
}: AdSlotProps) => {
  const {
    pageMetaData: { adZone, noAds },
    adSizePrediction,
    pushedAdSlots,
    featureFlags,
    device,
  } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./AdslotNew.module.scss') : require('./AdSlot.module.scss');
  const isDebug = useAdDebug();
  const fullAdSlotName = keepName(name) ? name : `${name}_${position}`;
  const isForMobile = viewport === 'mobile';
  const displayBasedOnViewport = device === viewport || viewport === AdSlotType.Default;
  const doPreserveSpace = shouldPreserveSpace(adZone, name, isForMobile, position);
  const showAdSizePrediction = !skipPrediction && adZone !== NO_AD_AD_ZONE;

  // AdAlliance relies on the existence of the following classname on the adSlot-wrapper
  const emsSelectorClassName = getAdslotName(viewport, fullAdSlotName, showAdSizePrediction, doPreserveSpace);

  useEffect(() => {
    if (noAds) {
      return;
    }

    if (name === AdSlotName.HeaderKombi) {
      AdAllianceLib.pushAdSlot('wallpaper_1' as AdSlotName);
      AdAllianceLib.pushAdSlot('superbanner_1' as AdSlotName);
      pushedAdSlots.push('wallpaper_1');
      pushedAdSlots.push('superbanner_1');
    } else if (shouldPushAd(name, fullAdSlotName, displayBasedOnViewport, pushedAdSlots)) {
      AdAllianceLib.pushAdSlot(fullAdSlotName as AdSlotName);
      pushedAdSlots.push(fullAdSlotName);
    }
  }, [name, noAds, fullAdSlotName, displayBasedOnViewport, pushedAdSlots]);

  if (noAds) {
    return null;
  }

  let minHeight = !skipPrediction
    ? predictionMinHeight({
        adSizePrediction,
        adSlotName: fullAdSlotName,
        adZone,
        isMobile: isForMobile,
      })
    : 'auto';

  if (doPreserveSpace) {
    minHeight = preserveSpaceStatic;
  }

  const classNames = clsx(emsSelectorClassName, styles[`${viewport}-viewport`], styles[horizontalAlign], className, {
    [styles.minHeightAuto]: minHeight === 'auto',
  });

  if (isDebug && displayBasedOnViewport) {
    return (
      <div
        className={clsx(classNames, styles.placeholder, 'placeholder')}
        style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}
        data-ada-container={`${fullAdSlotName}_${viewport}`}
      >
        Ad Slot: {fullAdSlotName}
        <br />
        type: {viewport}
        <br />
      </div>
    );
  }

  if (isOutbrain(name) && displayBasedOnViewport) {
    return (
      <OutbrainAd
        widgetId={outbrainWidgetId}
        className={clsx(styles.outbrainWrapper, styles[`${viewport}-viewport`], className)}
        fullAdSlotName={`${fullAdSlotName}_${viewport}`}
      />
    );
  }

  if (displayBasedOnViewport) {
    return (
      <DefaultAd
        className={classNames}
        minHeight={minHeight}
        adName={name}
        fullAdSlotName={fullAdSlotName}
        type={viewport}
        hidePlaceholder={minHeight === 'auto'}
      />
    );
  }

  return null;
};
