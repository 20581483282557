import { clsx } from 'clsx';
import { type LinkProps } from 'next/link';
import Image, { type ImageProps } from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import type { PropsWithClassName } from 'types/react-props';
import { getRefreshSizes } from 'utils/image';
import { formatMillisecondsToMinutes } from 'utils/time';
import { linkUrl } from 'utils/url';
import { Icon } from '../Icon';
import { Logo } from '../Logo';
import { SponsoredIcon } from '../SponsoredIcon';
import styles from './TeaserNew.module.scss';

export type TeaserNewProps = PropsWithClassName<{
  readonly title: string;
  readonly linkHref: LinkProps['href'];
  readonly openLinkInNewTab?: boolean;
  readonly image?: ImageProps;
  readonly subtitle?: string;
  readonly variant?: 'small' | 'medium' | 'large' | 'color';
  readonly subtitleColor?: string;
  readonly backgroundColor?: string;
  readonly isSponsored?: boolean;
  readonly onClick?: () => void;
  readonly isGallery?: boolean;
  readonly isVideo?: boolean;
  readonly isHighlight?: boolean;
  readonly isRtlPlus?: boolean;
  readonly galleryCount?: number;
  readonly videoDuration?: number;
}>;

const getTeaserSizes = (variant: string) => {
  switch (variant) {
    case 'large':
      return getRefreshSizes('teaserLarge');
    case 'small':
      return getRefreshSizes('teaserSmall');
    case 'medium':
    default:
      return getRefreshSizes('teaserMedium');
  }
};

const TeaserNew = ({
  title,
  subtitle,
  linkHref,
  openLinkInNewTab = false,
  image,
  variant = 'medium',
  subtitleColor,
  backgroundColor,
  isSponsored = false,
  isGallery = false,
  isVideo = false,
  isHighlight = false,
  isRtlPlus = false,
  galleryCount,
  videoDuration,
  className,
  onClick,
}: TeaserNewProps) => (
  <div
    className={clsx(styles.teaser, className, {
      [styles.small]: variant === 'small',
      [styles.medium]: variant === 'medium',
      [styles.large]: variant === 'large',
      [styles.color]: variant === 'color',
      [styles.highlight]: isHighlight,
    })}
    data-id={variant === 'large' ? 'lead' : undefined}
    style={{ backgroundColor: variant === 'color' ? (backgroundColor ?? '#000') : undefined }}
  >
    <div className={styles.imageWrapper}>
      <Image
        className={styles.image}
        image={image?.image}
        cropOverride={variant === 'large' || variant === 'color' ? 'c16_9' : 'c3_4'}
        alt={image?.alt}
        width={image?.width}
        src={image?.src}
        sizes={getTeaserSizes(variant)}
      />
      {isSponsored && <SponsoredIcon className={styles.sponsored} />}
      {isGallery && variant === 'small' && (
        <div className={styles.button}>
          <Icon className={styles.iconGallery} name="gallery" size={16} />
          {galleryCount}
        </div>
      )}
      {isVideo && variant === 'small' && (
        <div className={styles.button}>
          <Icon className={styles.iconPlay} name="play" size={16} />
          {!!videoDuration && formatMillisecondsToMinutes(videoDuration, '', false)}
        </div>
      )}
    </div>
    <div className={clsx(styles.contentWrapper, { [styles.paddingBottom]: !isVideo && !isRtlPlus })}>
      {!isGallery ? (
        <Link
          className={styles.link}
          href={linkUrl(linkHref)}
          target={openLinkInNewTab || isRtlPlus ? '_blank' : undefined}
          data-tracking-id="teaser-link"
        >
          <h3 className={styles.title}>
            {isHighlight ? <span style={{ backgroundColor: subtitleColor ?? undefined }}>{title}</span> : title}
          </h3>
        </Link>
      ) : (
        <button className={styles.link} onClick={onClick}>
          <h3 className={styles.title}>{title}</h3>
        </button>
      )}
      {!!subtitle && !isRtlPlus && (
        <div
          className={styles.subtitle}
          style={{ backgroundColor: variant !== 'color' && !!subtitleColor ? subtitleColor : undefined }}
        >
          {subtitle}
        </div>
      )}
      {(isVideo || isRtlPlus) && variant !== 'small' && (
        <div className={clsx(styles.button, { [styles.rtlPlus]: isRtlPlus })}>
          <Icon className={styles.iconPlay} name="play" size={20} />
          {isVideo && (
            <span>
              Video {!!videoDuration && `[ ${formatMillisecondsToMinutes(videoDuration, '', false)} ]`} • Jetzt ansehen
            </span>
          )}
          {isRtlPlus && (
            <>
              <span>
                Jetzt <strong>auf RTL+</strong> streamen
              </span>
              <Icon className={styles.iconExternalLink} name="externalLink" size={16} />
            </>
          )}
        </div>
      )}
    </div>
    {isGallery && variant !== 'small' && (
      <div className={clsx(styles.galleryCount)}>
        <Icon name="gallery" size={16} />
        {galleryCount}
      </div>
    )}
    {isRtlPlus && variant !== 'small' && (
      <Logo className={styles.rtlPlusLogo} size="teaser" variant="rtlPlus" colors={['#ff5c01', '#d4000d', '#6e0ced']} />
    )}
  </div>
);

export default TeaserNew;
