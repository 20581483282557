'use client';
import type { ReactElement } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { UUID } from 'utils/idGenerator';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

type SwiperProps = {
  title?: string;
  items: Array<ReactElement>;
  identifier?: string;
  variant?: 'news' | 'links';
  anchor?: string;
};

const Swiper = ({ title, items, variant = 'links', anchor }: SwiperProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./SwiperNew.module.scss') : require('./Swiper.module.scss');

  const [showArrows, setShowArrows] = useState(false);
  const [scrolledToStart, setScrolledToStart] = useState(true);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);

  const sliderRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const sliderCurrentRef = sliderRef.current;

    if (sliderCurrentRef !== null) {
      const { scrollLeft, scrollWidth, offsetWidth } = sliderCurrentRef;

      if (scrollLeft === 0) {
        setScrolledToStart(true);
      } else {
        setScrolledToStart(false);
      }

      if (scrollLeft + offsetWidth === scrollWidth) {
        setScrolledToEnd(true);
      } else {
        setScrolledToEnd(false);
      }
    }
  };

  useEffect(() => {
    const sliderCurrentRef = sliderRef.current;

    if (sliderCurrentRef !== null) {
      const { clientWidth, scrollWidth } = sliderCurrentRef;

      if (scrollWidth > clientWidth) {
        setShowArrows(true);
      }
    }

    sliderCurrentRef?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      sliderCurrentRef?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const slide = (direction: 'prev' | 'next') => {
    const sliderCurrentRef = sliderRef.current;

    if (sliderCurrentRef !== null) {
      const { scrollLeft, clientWidth } = sliderCurrentRef;

      let left: number;

      switch (direction) {
        case 'prev':
          left = scrollLeft - clientWidth;
          break;
        case 'next':
        default:
          left = scrollLeft + clientWidth;
          break;
      }

      sliderCurrentRef?.scroll({
        left,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className={styles.section} id={anchor}>
      <div className={styles.header}>
        {title && (
          <Typography
            as={variant === 'news' ? 'div' : 'h3'}
            variant="3xs"
            className={clsx(styles.title, 'swiperTitle', {
              [styles.links]: variant === 'links',
              [styles.news]: variant === 'news',
            })}
          >
            {title}
          </Typography>
        )}
      </div>

      {showArrows && (
        <>
          <button
            className={clsx(styles.arrow, styles.prev)}
            onClick={() => slide('prev')}
            aria-label="Zurück"
            disabled={scrolledToStart}
          >
            <Icon name="chevronLeft" />
          </button>
          <button
            className={clsx(styles.arrow, styles.next)}
            onClick={() => slide('next')}
            aria-label="Weiter"
            disabled={scrolledToEnd}
          >
            <Icon name="chevronRight" />
          </button>
        </>
      )}

      <div ref={sliderRef} className={styles.slider}>
        {items.map((item) => (
          <div key={UUID()} className={styles.teaserWrapper}>
            <div
              className={clsx(styles.teaser, 'swiperTeaser', {
                [styles.links]: variant === 'links',
                [styles.news]: variant === 'news',
              })}
            >
              {item}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Swiper;
