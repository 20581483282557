'use client';
import { Collapsible } from 'components/UI/Collapsible';
import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import { Typography } from 'components/UI/Typography';
import type { VideoProps } from 'types/videoTypes';
import styles from './widget-video-detail.module.scss';

export type WidgetVideoDetailProps = {
  readonly items: ReadonlyArray<{ model: VideoProps }>;
  readonly template: 'video';
  readonly view: 'detail';
};

const WidgetVideoDetail = ({ context }: { context: WidgetVideoDetailProps }) => {
  const {
    lastmodifiedAt,
    fields: { title, subtitle, text, description },
  } = context.items[0].model;

  const publishDate = new Date(lastmodifiedAt);

  return (
    <div>
      <Typography className={styles.headline} as="h2">
        {subtitle && (
          <Typography as="span" variant="xs">
            {subtitle}
          </Typography>
        )}
        {subtitle && title && <span className={styles.separator}>: </span>}
        {title && (
          <Typography as="span" variant="2xl" className={styles.title}>
            {title}
          </Typography>
        )}
      </Typography>
      {context.items[0] && <FoundationPlayer video={context.items[0].model} />}
      <Typography className={styles.date} variant="4xs" as="div">
        <time dateTime={lastmodifiedAt}>
          {publishDate.toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}{' '}
          um{' '}
          {publishDate.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          Uhr
        </time>
      </Typography>

      {description && (
        <Typography className={styles.content} as="div">
          <p>{description}</p>
        </Typography>
      )}
      {text && (
        <Collapsible
          showMoreText="Videotranskript ausklappen"
          showLessText="Videotranskript einklappen"
          className={styles.text}
        >
          {text}
        </Collapsible>
      )}
    </div>
  );
};

export default WidgetVideoDetail;
