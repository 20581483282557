'use client';
import { type MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { BurgerMenu, Icon } from 'components/UI/Icon';
import Link from 'components/UI/Link/Link';
import { Logo } from 'components/UI/Logo';
import MenuLink from 'components/UI/Menu/MenuLink';
import { Modal } from 'components/UI/Modal';
import { Search } from 'components/UI/Search';
import { useNoNav } from 'customHooks/useNoNav';
import useWindowDimensions from 'customHooks/useWindowDimensions';
import type { PropsWithClassName } from 'types/react-props';
import { slugify } from 'utils/string';
import { linkUrl } from 'utils/url';
import type { MenuItemProps, WidgetMenuProps } from './widget-menu-default';
import styles from './widget-menu-header.module.scss';

const MenuListAriaLabels = ['Primary', 'Primary', 'Secondary', 'Secondary'];
const MenuItemDataVarsAction = ['tab', 'level_1', 'level_2', 'level_3'];

const MenuRest = ({ menuRest, menuListLevel }: { menuRest: Array<MenuItemProps>; menuListLevel: number }) => (
  <div className={styles.menuRest} aria-label="Weitere Einträge" aria-haspopup>
    <Icon name="bullets" />
    <ul role="menu" aria-label="Secondary" className={styles.menuList}>
      {menuRest.map((menuItem) => (
        <li key={slugify(`${menuListLevel}-${menuItem.title}`)} className={styles.menuItem}>
          <MenuLink menuItem={menuItem} dataVarsAction={`level_${menuListLevel}`} />
        </li>
      ))}
    </ul>
  </div>
);

export const MenuItem = ({
  item,
  parentMenuListLevel,
  breakupPointForRestMenu,
  backgroundColor,
  className,
  disableClick,
  firstLevelActive,
  firstLevelClickEvent,
}: {
  item: MenuItemProps;
  parentMenuListLevel: number;
  breakupPointForRestMenu: number;
  backgroundColor?: string;
  className?: string;
  disableClick?: boolean;
  firstLevelActive?: boolean;
  firstLevelClickEvent?: MouseEventHandler<HTMLLIElement>;
}) => {
  const [accordionItemOpen, setAccordionItemOpen] = useState<boolean>(false);
  const showAccordionIcon = parentMenuListLevel === 1 && item.children;

  const isActive = disableClick ? firstLevelActive : !!item.active;
  const isActiveWithChildren = isActive && !!item.children;
  const isActiveWithoutChildren = isActive && item.children === undefined;
  const hasActiveChild = item.activeChildIndex !== undefined;

  const itemClassNames = clsx(
    styles.menuItem,
    className,
    isActive && styles.active,
    isActiveWithChildren && styles.isActiveWithChildren,
    isActiveWithoutChildren && styles.isActiveWithoutChildren,
    hasActiveChild && styles.hasActiveChild,
    accordionItemOpen && styles.accordionItemOpen
  );
  const nextMenuListLevel = parentMenuListLevel + 1;

  return (
    <li
      role="menuitem"
      aria-haspopup={!!item.children && parentMenuListLevel !== 0 ? true : undefined}
      style={{ backgroundColor: backgroundColor }}
      className={itemClassNames}
      onClick={firstLevelClickEvent}
      onKeyDown={() => null}
    >
      <span className={showAccordionIcon ? styles.flexContainer : undefined}>
        <MenuLink
          menuItem={item}
          dataVarsAction={MenuItemDataVarsAction[parentMenuListLevel]}
          disableClick={disableClick}
        />
        {showAccordionIcon && (
          <button
            onClick={() => setAccordionItemOpen(!accordionItemOpen)}
            aria-label="Accordion Item"
            type="button"
            className={clsx(styles.accordionIcon, showAccordionIcon && styles.visible)}
          >
            <Icon name="caretSmallDown" />
          </button>
        )}
      </span>
      {item.children && (
        <MenuList
          menu={item.children}
          menuListLevel={nextMenuListLevel}
          breakupPointForRestMenu={breakupPointForRestMenu}
          parentItem={item}
        />
      )}
    </li>
  );
};

type MenuListProps = PropsWithClassName<{
  readonly menu: ReadonlyArray<MenuItemProps>;
  readonly menuListLevel: number;
  readonly breakupPointForRestMenu: number;
  readonly parentItem?: MenuItemProps;
  readonly burgerMenuOpen?: boolean;
}>;

export const MenuList = ({
  menu,
  menuListLevel,
  breakupPointForRestMenu,
  parentItem,
  burgerMenuOpen,
  className,
}: MenuListProps) => {
  const activeItem = useMemo(() => menu?.find((item) => item.active), [menu]);
  const hasActiveItem = !!activeItem;
  const hasActiveItemWithChildren = !!activeItem?.children;
  const hasActiveItemWithoutChildren = hasActiveItem && !activeItem.children;
  const activeGrandChild = useMemo(
    () => hasActiveItemWithChildren && activeItem.children.find((item) => item.active),
    [hasActiveItemWithChildren, activeItem]
  );
  const hasActiveGrandChildWithChildren = activeGrandChild && !!activeGrandChild?.children;

  const showBackIcon = hasActiveItem && !hasActiveItemWithoutChildren;

  const menuRest: Array<MenuItemProps> = [];
  let countCharacters = menuListLevel >= 2 ? (parentItem?.title?.length ?? 0) + 5 : 0;

  const clickDisabled = menuListLevel === 0 && burgerMenuOpen;
  const initialFirstLevelActiveIndex = menuListLevel === 0 && menu ? menu.findIndex((item) => item.active) : undefined;
  const [firstLevelActiveIndex, setFirstLevelActiveIndex] = useState<number | undefined>(initialFirstLevelActiveIndex);

  useEffect(() => {
    setFirstLevelActiveIndex(initialFirstLevelActiveIndex);
  }, [initialFirstLevelActiveIndex, clickDisabled]);

  const listClassNames = clsx(
    className,
    styles.menuList,
    styles[`menuListLevel${menuListLevel}`],
    hasActiveItemWithChildren && styles.hasActiveItemWithChildren,
    hasActiveGrandChildWithChildren && styles.hasActiveGrandChildWithChildren
  );

  return (
    <ul
      key={`menuList${menuListLevel}`}
      role="menu"
      aria-label={MenuListAriaLabels[menuListLevel]}
      className={listClassNames}
    >
      {showBackIcon && parentItem && (
        <a
          className={styles.backIcon}
          href={linkUrl(parentItem.url, true)}
          aria-label={`Zurück zu ${parentItem.title}`}
          role="menuitem"
        >
          <Icon name="chevronLeft" />
        </a>
      )}
      {menu?.map((item, index) => {
        let hexOpacityColor;
        if (menuListLevel === 0) {
          const opacity = (1 + index) * 6;
          const opacityHex = opacity.toString().padStart(2, '0');
          hexOpacityColor = firstLevelActiveIndex === index ? '#fff' : `#020203${opacityHex}`;
        }

        countCharacters = countCharacters + item.title?.length + 4;
        countCharacters >= breakupPointForRestMenu && menuRest.push(item);
        const classNames = clsx(countCharacters >= breakupPointForRestMenu && styles.hidden);

        return (
          <MenuItem
            key={slugify(`${menuListLevel}-${item.title}`)}
            item={item}
            parentMenuListLevel={menuListLevel}
            breakupPointForRestMenu={breakupPointForRestMenu}
            backgroundColor={menuListLevel === 0 ? hexOpacityColor : undefined}
            className={classNames}
            disableClick={clickDisabled}
            firstLevelActive={clickDisabled && firstLevelActiveIndex === index}
            firstLevelClickEvent={() => clickDisabled && setFirstLevelActiveIndex(index)}
          />
        );
      })}

      {menuRest.length > 0 && <MenuRest menuRest={menuRest} menuListLevel={menuListLevel} />}
    </ul>
  );
};

const WidgetMenuHeader = ({ context }: { context: WidgetMenuProps }) => {
  const noNav = useNoNav();
  const { width } = useWindowDimensions();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [breakupPointForRestMenu, setBreakupPointForRestMenu] = useState<number>(9999);
  const [siteLoaded, setSiteLoaded] = useState<boolean>(false);

  useEffect(() => {
    switch (true) {
      case width > 1799:
        setBreakupPointForRestMenu(134);
        break;
      case width > 1659:
        setBreakupPointForRestMenu(124);
        break;
      case width > 1535:
        setBreakupPointForRestMenu(112);
        break;
      case width > 1365:
        setBreakupPointForRestMenu(105);
        break;
      case width > 1279:
        setBreakupPointForRestMenu(94);
        break;
      case width > 1023:
        setBreakupPointForRestMenu(91);
        break;
      case width > 899:
        setBreakupPointForRestMenu(104);
        break;
      case width > 767:
        setBreakupPointForRestMenu(90);
        break;
      default:
        setBreakupPointForRestMenu(9999);
    }

    setSiteLoaded(true);
  }, [width]);

  if (noNav) return null;

  return (
    <header className={styles.header} data-testid="header">
      <div className={styles.top}>
        <Link href="/" className={styles.logo}>
          <Logo />
        </Link>
        <button
          className={clsx(styles.searchButton, styles.desktopHidden)}
          onClick={() => setSearchOpen(true)}
          aria-label="Suche"
        >
          <Icon name="search" />
        </button>

        <BurgerMenu
          isActive={burgerMenuOpen}
          onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
          className={styles.burgerMenu}
        />

        <nav className={clsx(styles.headerNav, burgerMenuOpen && styles.burgerMenuOpen)}>
          <MenuList
            menu={context?.model?.menu}
            menuListLevel={0}
            breakupPointForRestMenu={breakupPointForRestMenu}
            burgerMenuOpen={burgerMenuOpen}
            className={clsx(siteLoaded && styles.siteLoaded, burgerMenuOpen && styles.burgerMenuOpen)}
          />
          <button className={clsx(styles.searchButton, styles.mobileHidden)} onClick={() => setSearchOpen(true)}>
            <Icon name="search" />
          </button>
        </nav>

        <Modal onClose={() => setSearchOpen(false)} isOpen={searchOpen}>
          <Search />
        </Modal>
      </div>
    </header>
  );
};

export default WidgetMenuHeader;
