'use client';
import { useRef, useState } from 'react';
import { CreditBox } from 'components/UI/Creditbox/Creditbox';
import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import VideoSwiperNew from 'components/UI/VideoSwiper/VideoSwiper';
import type { VideoProps } from 'types/videoTypes';
import styles from './widget-video-with-related.module.scss';

export const WidgetVideoWithRelated = ({ context }: { context: VideoProps }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [showRelatedVideos, setShowRelatedVideos] = useState(false);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const startType = useRef(0);
  const { related = [], ...video } = context;
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [totalClips, setTotalClips] = useState<number>(0);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  const currentVideo = currentVideoIndex === -1 ? video : related[currentVideoIndex];

  return (
    <div className={styles.main} data-testid="video-player">
      <FoundationPlayer
        video={currentVideo}
        postRollDisabled
        showRelatedVideosListener={setShowRelatedVideos}
        isAdPlayingListener={setIsAdPlaying}
        totalClipsListener={setTotalClips}
        totalDurationListener={setTotalDuration}
        currentAdTimeListener={setCurrentAdTime}
        videoPlayingListener={setVideoPlaying}
      />

      <CreditBox video={currentVideo} />
      {related.length > 0 && (
        <VideoSwiperNew
          onClick={(index: number) => {
            startType.current = index;
            setCurrentVideoIndex(index);
          }}
          videoList={related}
          isAdPlaying={isAdPlaying}
          totalAdClips={totalClips}
          totalAdDuration={totalDuration}
          currentAdTime={currentAdTime}
          currentVideoIndex={currentVideoIndex}
          videoPlaying={videoPlaying}
          initHidden
          showRelatedVideos={showRelatedVideos}
          className={styles.swiperNewRoot}
        />
      )}
    </div>
  );
};
