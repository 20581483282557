import { type RefObject, useEffect, useRef } from 'react';

export const useScrollToCurrentSelection = (selectedIndex: number): RefObject<HTMLUListElement | null> => {
  const scrollRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (!scrollRef.current?.children) {
      return;
    }

    const selectedTeaser = scrollRef.current.children[selectedIndex] as HTMLUListElement;
    if (!selectedTeaser) {
      return;
    }

    scrollRef.current.scroll({
      left: selectedTeaser.offsetLeft - scrollRef.current.offsetLeft,
      behavior: 'smooth',
    });
  }, [selectedIndex]);

  return scrollRef;
};
