export const fetchWithTimeout = async (url: string, attr?: RequestInit, timeout: number = 5000) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, { signal: controller.signal, ...attr });
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      console.log('Request timed out.');
    } else {
      console.error('Fetch error:', error);
    }
  } finally {
    clearTimeout(timeoutId);
  }
};
