import { clsx } from 'clsx';
import Icon from '../../Icon/Icon';
import styles from './NavButtons.module.scss';

interface NavButtonsProps {
  scrollLeft: (howFar: number) => void;
  scrollRight: (howFar: number) => void;
  showLeft: boolean;
  showRight: boolean;
  className: string;
}

const NavButtons = ({ scrollLeft, scrollRight, showLeft, showRight, className }: NavButtonsProps) => (
  <div className={className}>
    {showLeft && (
      <button
        className={clsx(styles.button, styles.buttonLeft, 'navButtons')}
        onClick={() => scrollLeft(2)}
        data-testid="button-left"
        aria-label="Vorherige Videos"
      >
        <Icon name="chevronLeft" size={25} />
      </button>
    )}
    {showRight && (
      <button
        className={clsx(styles.button, styles.buttonRight, 'navButtons')}
        onClick={() => scrollRight(2)}
        data-testid="button-right"
        aria-label="Nächste Videos"
      >
        <Icon name="chevronRight" size={25} />
      </button>
    )}
  </div>
);

export default NavButtons;
