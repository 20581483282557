import type { VideoProps } from 'types/videoTypes';
import { formatSecondsToMinutes } from 'utils/time';
import { Icon } from '../Icon';
import Image from '../Image/Image';
import styles from './VideoThumbnail.module.scss';

export interface VideoThumbnailProps {
  title: string;
  kicker: string;
  duration: number;
  isActive: boolean;
  video: VideoProps;
  onClick?: () => void;
  isAdPlaying?: boolean;
}

const VideoThumbnailNew = ({
  title,
  kicker,
  duration,
  onClick,
  isActive = false,
  video,
  isAdPlaying,
}: VideoThumbnailProps) => {
  return (
    <button onClick={onClick} className={styles.main} aria-label={`Video abspielen: ${title}`} aria-current={isActive}>
      <div className={styles.poster}>
        <Image image={video.image} alt={`${title} ${kicker}`} />
        {isActive && !isAdPlaying && <div className={styles.activeIndicator}>läuft gerade</div>}
        <div className={styles.duration}>
          <Icon name="play" size={16} />
          {formatSecondsToMinutes(duration)}
        </div>
      </div>
      <p className={styles.title}>{title}</p>
    </button>
  );
};

export default VideoThumbnailNew;
