import type { CSSProperties, JSX, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from 'types/react-props';
import { responsiveSpacing } from 'utils/styled';
import type { SpacingSize } from 'utils/themes';
import styles from './Typography.module.scss';

export const variants = [
  'default',
  '4xl',
  '3xl',
  '2xl',
  'xl',
  'l',
  'm',
  's',
  'xs',
  '2xs',
  '3xs',
  '4xs',
  '5xs',
  '6xs',
] as const;

export type TypographyVariants = (typeof variants)[number];
export type TypographyAlignment = 'left' | 'center' | 'right';

export type TypographyProps = PropsWithClassName<
  PropsWithChildren<{
    readonly as?: keyof JSX.IntrinsicElements;
    readonly variant?: TypographyVariants;
    readonly textAlign?: TypographyAlignment;
    readonly underlined?: boolean;
    readonly style?: CSSProperties;
    readonly marginBottom?: SpacingSize;
  }>
>;

const Typography = ({
  children,
  as: DynamicTag = 'p',
  variant = 'default',
  textAlign = 'left',
  className,
  underlined = false,
  marginBottom,
  style = {},
}: TypographyProps) => {
  const mergedStyle = {
    ...style,
    ...(marginBottom ? responsiveSpacing('marginBottom', marginBottom) : {}),
  };

  return (
    <DynamicTag
      className={clsx(
        styles[`text-${variant}`],
        styles[`align-${textAlign}`],
        className,
        {
          [styles.underlined]: underlined,
        },
        styles['margin']
      )}
      style={mergedStyle}
    >
      {children}
    </DynamicTag>
  );
};

export default Typography;
