import type { GeoCountries, VideoEncoding } from '../types/videoTypes.d.ts';

export const COUNTRIES_DACH = ['de', 'at', 'ch'];
export const COUNTRIES_DACHLILU = ['de', 'at', 'ch', 'li', 'lu'];

export const geoAllowed = (videoAllowedForCountry: GeoCountries, clientCountryCode: string) => {
  if (videoAllowedForCountry === 'world') {
    return true;
  }

  if (videoAllowedForCountry === 'dachlilu') {
    return COUNTRIES_DACHLILU.includes(clientCountryCode.toLowerCase());
  }

  if (videoAllowedForCountry === 'dach') {
    return COUNTRIES_DACH.includes(clientCountryCode.toLowerCase());
  }

  return clientCountryCode.toLowerCase() === 'de';
};

export const getVideoEncoding = (
  encodings?: Array<VideoEncoding>,
  format: string = 'progressive'
): VideoEncoding | undefined => {
  if (!encodings) {
    return undefined;
  }

  const pin = encodings.find(({ value }) => value['encoding-format'].value === format);

  if (pin) {
    return pin;
  }

  return encodings.length > 0 ? encodings[0] : undefined;
};

export const getVideoDuration = (encodings?: Array<VideoEncoding>): number => {
  if (!encodings) {
    return 0;
  }

  const video = getVideoEncoding(encodings);

  return video ? parseFloat(video.value.duration.value) / 1000 : 0;
};
