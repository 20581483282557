'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import type { PropsWithClassName } from 'types/react-props';
import { isFeatureFlagEnabled } from 'utils/featureFlags';

export type LogoProps = PropsWithClassName<{
  readonly size?: 'medium' | 'small' | 'teaser' | 'header' | 'tiny' | 'headline';
  readonly variant?: 'default' | 'rtlPlus' | 'rtlDe';
  readonly colors?: [string, string, string];
}>;

const Logo = ({ size = 'medium', variant, colors, className }: LogoProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./LogoNew.module.scss') : require('./Logo.module.scss');

  const classNames = clsx(
    styles.logo,
    {
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.teaser]: size === 'teaser',
      [styles.header]: size === 'header',
      [styles.tiny]: size === 'tiny',
    },
    className
  );

  if (variant === 'rtlPlus') {
    return (
      <svg className={classNames} viewBox="0 0 82 12" role="img" name="RTL+ Logo">
        <title>RTL+</title>
        <path fill={colors?.[0] ?? '#020203'} d="M0 0h21.338v12H0z" />
        <path
          fill="#fff"
          d="M6.061 2.758h5.686c1.731 0 2.695.778 2.695 2.084 0 1.12-.723 1.871-1.954 2.047l2.788 2.353h-2.139l-2.621-2.27H7.544v2.269H6.062V2.758h-.001Zm5.492 3c.917 0 1.361-.296 1.361-.898 0-.603-.444-.89-1.361-.89H7.544v1.787h4.009v.001Z"
        />

        <path fill={colors?.[1] ?? '#020203'} d="M23.47 0h21.338v12H23.47z" />
        <path fill="#fff" d="M33.397 4.036h-3.75V2.758h8.983v1.278h-3.75v5.206h-1.483V4.036Z" />

        <path fill={colors?.[2] ?? '#020203'} d="M46.939 0h21.338v12H46.939z" />
        <path fill="#fff" d="M53.57 2.758h1.481v5.206h6.594v1.278H53.57V2.758Z" />

        <path
          fill={colors ? '#fff' : '#020203'}
          d="M81.081 5.233h-3.496V2.594h-1.546v2.639h-3.493v1.548h3.493v2.621h1.546V6.781h3.496V5.233Z"
        />
      </svg>
    );
  }

  if (variant === 'rtlDe') {
    return (
      <svg className={classNames} viewBox="0 0 144 20" role="img" name="RTL.de Logo">
        <title>RTL.de</title>
        <path fill={colors?.[0] ?? '#2a08b5'} d="M0 0h35.568v20H0z" />
        <path
          fill="#fff"
          d="m17.528 11.622 4.368 3.781h3.566l-4.647-3.921c1.075-.154 1.87-.564 2.398-1.117.541-.565.859-1.329.859-2.296 0-1.128-.42-1.97-1.164-2.552-.711-.556-1.808-.92-3.328-.92h-9.478v10.806h2.47l-.001-3.781h4.957Zm-4.956-5.006h6.684c.688 0 1.28.087 1.708.365v.001c.389.253.561.649.561 1.117 0 .469-.172.868-.561 1.126-.427.282-1.019.372-1.707.372h-.5v-.002h-6.185V6.616Z"
        />

        <path fill={colors?.[1] ?? '#6f0bed'} d="M38.999 0h35.568v20H38.999z" />
        <path fill="#fff" d="M55.547 6.727h-6.251v-2.13h14.973v2.13h-6.251v8.676h-2.471V6.727Z" />

        <path fill={colors?.[2] ?? '#ec33ec'} d="M78.001 0h35.567v20H78.001z" />
        <path fill="#fff" d="M89.054 4.597h2.469v8.674h10.991v2.13h-13.46V4.597Z" />

        <path d="M143.033 15.477h-7.386V4.614h7.386v1.707h-5.478v2.7h4.997v1.707h-4.997v3.042h5.478v1.707Zm-15.408 0h-4.004V4.614h4.004c3.15 0 5.23 2.141 5.23 5.431 0 3.244-2.111 5.432-5.23 5.432Zm-2.095-9.156v7.449h1.955c2.188 0 3.43-1.366 3.43-3.725 0-2.374-1.242-3.724-3.43-3.724h-1.955Z" />
      </svg>
    );
  }

  return (
    <svg className={classNames} viewBox="0 0 160 28" focusable="false" role="img" name="RTL Logo">
      <title>RTL</title>
      <path fill={colors?.[0] ?? '#0036f2'} d="M0 0h49.725v28H0z" />
      <path
        fill={colors?.[0] === '#ffffff' ? '#000' : '#fff'}
        d="M14.101 6.41h13.224c4.049 0 6.275 1.821 6.275 4.857 0 2.632-1.687 4.386-4.588 4.79l6.477 5.466h-4.993l-6.14-5.263H17.61v5.263h-3.441V6.41H14.1Zm12.82 7.017c2.159 0 3.17-.675 3.17-2.092s-1.011-2.092-3.17-2.092H17.61v4.184h9.31Z"
      />

      <path fill={colors?.[1] ?? '#00acf2'} d="M54.718 0h49.725v28H54.718z" />
      <path
        fill={colors?.[1] === '#ffffff' ? '#000' : '#fff'}
        d="M77.86 9.378h-8.77V6.41h20.982v2.968h-8.77v12.145H77.86V9.378Z"
      />

      <path fill={colors?.[2] ?? '#fa002e'} d="M109.436 0h49.725v28h-49.725z" />
      <path
        fill={colors?.[2] === '#ffffff' ? '#000' : '#fff'}
        d="M124.887 6.41h3.44v12.144h15.384v2.969h-18.824V6.41Z"
      />
    </svg>
  );
};

export default Logo;
