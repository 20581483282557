'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import type { MenuItemProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { Icon } from '../Icon';
import { Pills } from '../Pills/Pills';
import styles from './SearchBar.module.scss';

export const SEARCH_NAV: Array<MenuItemProps> = [
  {
    title: 'Alle Sendungen A-Z',
    url: 'https://www.rtl.de/sendungen/a-z.html',
    target: '_self',
    type: 'link',
  },
  {
    title: 'TV Programm',
    url: 'https://www.rtl.de/fernsehprogramm/',
    target: '_self',
    type: 'link',
  },
  {
    title: 'Videos',
    url: 'https://www.rtl.de/video/',
    target: '_self',
    type: 'link',
  },
  {
    title: 'Alle Themen A-Z',
    url: 'https://www.rtl.de/thema/a-z.html',
    target: '_self',
    type: 'link',
  },
  {
    title: 'Ganze Sendungen bei RTL+',
    url: 'https://plus.rtl.de/?utm_campaign=navilayer&utm_medium=owned&utm_source=rtl',
    target: '_blank',
    type: 'link',
  },
  {
    title: 'Live-TV auf RTL+',
    url: 'https://plus.rtl.de/video-tv/live-tv/rtl-1?utm_campaign=navilayer&utm_medium=owned&utm_source=rtl',
    target: '_blank',
    type: 'link',
  },
];

export const SearchBar = () => {
  const { featureFlags } = useContext(AppContext);
  const getPillNaviFromSiteManager = isFeatureFlagEnabled('ft_temp_pill_navi_sitemanager', featureFlags);
  return (
    <div>
      <form className={styles.form} target="_top" action="/suche">
        <Icon className={styles.icon} name="search" size={20} viewBox="0 0 20 20" />
        <input className={styles.input} type="search" name="q" placeholder="Suche" autoComplete="off" />
      </form>
      {!getPillNaviFromSiteManager && (
        <Pills items={SEARCH_NAV} className={clsx(styles.navItem, 'search-bar-pill')} disableActiveState />
      )}
    </div>
  );
};
