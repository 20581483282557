import { clsx } from 'clsx';
import styles from './Loader.module.scss';

export enum LoaderOptions {
  VideoWithRelated = 'videoWithRelated',
}

export const VideoPlayerControlsLoading = ({ className = '' }: { className?: string }) => (
  <div className={clsx(styles.root, className)}>
    <div className={clsx(styles.box, styles.box1)} />
    <div className={clsx(styles.box, styles.box2)} />
    <div className={clsx(styles.box, styles.box3)} />
  </div>
);

export const Loader = ({ type }: { type: LoaderOptions }) => {
  if (type == LoaderOptions.VideoWithRelated) {
    return (
      <div data-testid={`loader-${type}`}>
        <div className={styles.videoWithRelated}>
          <div className={styles.videoWithRelatedInside}>
            <VideoPlayerControlsLoading className={styles.loaderPadding} />
          </div>
        </div>
      </div>
    );
  }
  return null;
};
