import type { AnalyticsWidgetItem, GtmContentFields } from 'types/analytics';
import type { AnalyticsDataArticleProps } from '../context/AppContext';

export const getAgofCode = (fields: GtmContentFields) =>
  ['dbrsowf_rtl', fields.main_section, ...Object.values(fields.sub_sections ?? [])]
    // filter out sections "show" and "sendungen"
    .filter((value) => value !== 'show' && value !== 'sendungen')
    .join('');

export const SectionMapper = (sectionPath: string, isFrontpage: boolean = false): GtmContentFields => {
  const sections = sectionPath.split('/');
  const subSections = sections.slice(1);
  const formattedSubsections: { [key: string]: string } = {};
  subSections.forEach((section, index) => {
    formattedSubsections[`sub_section_${index + 1}`] = section;
  });

  return {
    main_section: isFrontpage ? 'newshome' : sections[0],
    sub_sections: formattedSubsections,
  };
};

export const getHomeSectionPath = (analyticsData: AnalyticsWidgetItem) =>
  analyticsData?.model?.homeSection?.fullPath?.replace(/(^\/|\/$)/g, '');

export const getGTMContentFields = (
  pageTitle?: string,
  pageType?: string,
  homeSectionPath?: string,
  articleFields?: AnalyticsDataArticleProps
) => {
  let fields: GtmContentFields = {};

  if (homeSectionPath) {
    if (pageType === 'page') {
      fields = SectionMapper(homeSectionPath, pageTitle === 'frontpage');
    }

    if (pageType === 'article' && articleFields) {
      fields = SectionMapper(homeSectionPath);
      fields.article_infos = articleFields;
    }
  } else {
    if (pageTitle === 'frontpage') {
      fields.main_section = 'newshome';
    }
  }

  return fields;
};

export const trackPageView = (fields: GtmContentFields, ivwCode: string, adZone: string, keywords?: Array<string>) => {
  const agofCode = getAgofCode(fields);

  window.dataLayer.push({
    content: null,
  });
  window.dataLayer.push({
    event: 'pageview',
    content: {
      agof_code: agofCode,
      ivw_code: ivwCode,
      main_section: fields.main_section ?? '',
      ad_zone: adZone,
      ad_keywords: keywords,
      ...fields.sub_sections,
      ...fields.article_infos,
    },
    tech: {
      development_version_id: process.env.APP_VERSION,
    },
  });
};
