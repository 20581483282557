import type { ImageLoaderProps } from 'next/image';
import { FALLBACK_IMAGE_URL } from 'components/UI/FallbackImage/FallbackImage';
import type { AllowedCropTypes, ApiImageProps } from 'components/UI/Image';
import { slugify } from './string';

export const getImageLoader = (src: string, filename: string, crop: AllowedCropTypes, mimeType = 'webp') => {
  const name = slugify(filename);

  if (src.includes('rtl.de')) {
    return {
      loader: ({ src, width }: ImageLoaderProps) => `${src}${crop}/${width}/${name}.${mimeType}`,
      unoptimized: false,
    };
  }

  return {
    // loader is in this case only a placeholder
    loader: ({ src }: ImageLoaderProps) => src,
    unoptimized: true,
  };
};

export const createThumbnailUrl = (image: ApiImageProps, title = 'image') => {
  if (image?.baseUrl && image?.originalWidth) {
    const width = image.originalWidth < 1686 ? image.originalWidth : 1686;
    return `${image.baseUrl}c16_9/${width}/${slugify(title) ?? 'image'}.webp`;
  }
  return FALLBACK_IMAGE_URL;
};
